<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '修改' : '新增' }}装备附件
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="deviceAnnexInfo" :rules="rules" ref="deviceAnnexInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id" required v-if="state"
                        class="input-lenght">
                        <el-input v-model="deviceAnnexInfo.id" autocomplete="off" :disabled="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="仪器仪表编号" :label-width="formLabelWidth" prop="did" required v-if="state"
                        class="input-lenght">
                        <el-input v-model="deviceAnnexInfo.did" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="附件" :label-width="formLabelWidth" prop="spareCode">
                        <el-select placeholder="请选择附件" v-model="deviceAnnexInfo.spareCode">
                            <el-option v-for="(item, index) in annexInfoList" :key="index" :label="item.spareName"
                                :value="item.spareCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="附件图片" :label-width="formLabelWidth" prop="spareImage">
                        <div class="bannerbox">
                            <div class="banners" v-for="(item, index) in imgsList" :key="index">
                                <i class="el-icon-error cha" @click="handleDel(item, index)" style="font-size:30px"></i>
                                <uploadImg :imageUrl="item.imageName" @imgVal="imgVal($event, item)" :type="item.type">
                                </uploadImg>
                            </div>
                            <div class="upload1" v-if="imageView">
                                <el-upload class="upload-demo1" action :show-file-list="false" multiple drag
                                    :http-request="handleUploadFile">
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">点击上传
                                        <!-- <div
                            class="el-upload__tip"
                            slot="tip"
                          >请上传图片</div> -->
                                    </div>
                                </el-upload>
                            </div>

                        </div>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button  class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset" v-if="!this.id">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>

<script>
import uploadImg from '@/components/UploadImg/index'
import { TestInfoRule } from '@/utils/vaildate.js'
import { uploadFile } from '@/api/file'
import {  getDeviceAnnex, addDeviceAnnex, updateDeviceAnnex } from '@/api/deviceAnnex.js'
import { getAnnexInfos } from '@/api/annex.js'
export default {
    components: {
        uploadImg
    },
    props: {
        id: {
            type: [String, Number]
        },
        did: {
            type: [String, Number]
        }
    },
    data() {
        return {
            annexInfoList: [],
            deviceAnnexInfo: {
                id: null,
                did: this.did,
                spareCode: null,
                spareImage: null,
            },
            formLabelWidth: "150px",
            rules: TestInfoRule,
            imgsList: [],
            imageView: true,
            path: process.env.VUE_APP_URL + this.UploadPath,
            submitFlag: false,// 是否提交中,
            state: this.id ? true : false
        }
    },
    mounted() {

        this.loadAnnexInfos()
        this.id && this.loadDeviceAnnex()
    },
    methods: {
        async loadAnnexInfos() {
            await getAnnexInfos().then(res => {
                if (res.code === '000000') {
                    this.annexInfoList = res.t
                }
            })
        },
        loadDeviceAnnex() {
            getDeviceAnnex(this.id).then(res => {
                if (res.code === '000000') {
                    this.deviceAnnexInfo = res.t
                    if (this.deviceAnnexInfo.spareImage != null && this.deviceAnnexInfo.spareImage != '') {
                        const list2 = this.deviceAnnexInfo.spareImage.split(";")
                        list2.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = {
                                    imageName: item
                                }
                                this.imgsList.push(obj)
                            }
                        })
                        if (this.imgsList.length > 3) {
                            this.imageView = false
                        }

                    }
                }
            })
        },


        //提交
        submitForm() {
            this.$refs.deviceAnnexInfo.validate((valid) => {
                if (valid) {
                    this.submitFlag = true
                    this.deviceAnnexInfo.spareImage = '';
                    this.imgsList.forEach(item => {
                        this.deviceAnnexInfo.spareImage += (item.imageName + ";")
                    })
                    if (this.id) {
                        updateDeviceAnnex(this.deviceAnnexInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addDeviceAnnex(this.deviceAnnexInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        //重置
        handleReset() {
            this.$refs.deviceAnnexInfo.resetFields()
            this.imgsList = []
            this.imageView = true
        },

        //上传图片
        handleUploadFile(file) {
            const formData = new FormData()
            formData.append('file', file.file)

            uploadFile(formData).then(res => {
                if (res.code === '000000') {
                    this.imageUrl = res.data.importFileName
                    const obj = {
                        imageName: res.data.importFileName
                    }
                    this.imgsList.push(obj)
                    // this.$emit('imgVal', res.data.path)
                    this.deviceAnnexInfo.spareImage = '';
                    this.imgsList.forEach(item => {
                        this.deviceAnnexInfo.spareImage += (item.imageName + ";")
                    })
                    if (this.imgsList.length > 3) {
                        this.imageView = false;
                    }

                }
            })
        },
        handleDel(item, index) {
            this.imgsList.splice(index, 1)
            this.imageView = true
        },
        // 图片上传后地址
        imgVal(val, item) {
            item.imageName = val
        },
    }
}
</script>

<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        width: 100%;
        text-align: left;
    }

    .input-lenght {
        width: 500px;

    }

    .input-upload {
        width: 500px;
        position: relative;

        .el-button {
            position: absolute;
            top: 0px;
            right: -100px;
        }
    }

    .upload1 {
        width: 360px;
        height: 180px;
        margin-right: 20px;
        margin-bottom: 20px;

        .el-upload-dragger,
        .el-upload--text,
        .upload-demo1 {
            width: 100%;
            height: 100%;
        }

        .el-upload-dragger .el-icon-upload {
            margin-top: 60px;
        }

        .el-upload__text {
            margin-top: 5px;
        }
    }

    .bannerbox {
        display: flex;
        flex-wrap: wrap;
    }

    .banners {
        width: 360px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        .cha {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            z-index: 2;
        }

        .el-upload__tip {
            margin-top: 7px;
        }

        .el-upload {
            width: 100%;
        }

        .el-upload-dragger {
            border: none;
            border-radius: 0;
        }
    }

    .avatar {
        width: 100%;
        height: 100%;
    }

    .btns {
        margin-top: 50px;
    }

    .upload-demo {
        width: 360px;
        height: 200px;
    }

    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }


}
</style>